import { getFirebaseBackend } from '../../helpers/firebase/authUtils'
import axios from '../../api/axios';
export const state = {
    currentUser: sessionStorage.getItem('user'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('user', newValue)
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    currentUser(state) {
        return state.currentUser
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({ commit }, { email, password }) {
        let loginData = {
            "email": email,
            "password": password,
        }
        return new Promise((resolve, reject) => {

           try{

            this.$axios.post('/login', loginData)
            .then((response) => {
                console.log(response);
                commit('SET_CURRENT_USER',response.user.result.user);
               
            }).catch(error => {

                console.log(error);
                reject(error);
                // dispatch('notification/error', error, { root: true });

            });
           }catch(error){
        console.log("Error : " , error);
           }

        })
        
    },

    // Logs out the current user.
    logOut({ commit }) {
        axios.post('/logout')
            .then((response) => {
                console.log(response)
                console.log(commit);
                // alert('here');
                // commit('SET_CURRENT_USER', null);
                localStorage.removeItem('user')
                 location.href = '/login';
            });
    },

    // register the user
    register({ commit, dispatch, getters }, { username, email, password,phoneNo } = {}) {
        // alert("here");
        // console.log('-----------user---------',this);
        // console.log(dispatch,commit,getters);
        if (getters.loggedIn) return dispatch('validate')

        let registerData = {
            "name": username,
            "email": email,
            "password": password,
            "phone": phoneNo
        }
        // console.log(registerData);

        return new Promise((resolve, reject) => {

           try{
            // alert("here");
            axios.post('/register', registerData)
            .then((response) => {
                const regUser = response
            console.log(commit,regUser)
             
            location.href = '/login';
               
            }).catch(error => {

                // console.log(error,'--------------error--------');
                reject(error);
                // dispatch('notification/error', error, { root: true });

            });
           }catch(error){
             console.log("Error : " , error);
           }

        })
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        //alert(email);
        if (getters.loggedIn) return dispatch('validate')
        let emailIt = {
            "email": email,
        }
        return new Promise((resolve, reject) => {

            try{
 
             axios.post('/check/email', emailIt)
             .then((response) => {
                 console.log(response);
                //  commit('SET_CURRENT_USER',response.user.result.user);
                
             }).catch(error => {
 
                 console.log(error);
                 reject(error);
                 // dispatch('notification/error', error, { root: true });
 
             });
            }catch(error){
         console.log("Error : " , error);
            }
 
         })
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
