
import axios from 'axios'

  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_URL}api`
  });
  
  instance.interceptors.request.use(function (config) {
    let user = localStorage.getItem('user') == "undefined" ? null : JSON.parse(localStorage.getItem('user'));
  
  if(user?.token){
   
        config.headers.Authorization = `Bearer ${user?.token}`
    }
  
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

 
  instance.interceptors.response.use((response)=>response, async (error)=>{
    if(error.response.status === 401){
        localStorage.removeItem('user')
        location.href = '/login';
    }

    return Promise.reject(error)
  });


export default instance