import axios from '../../api/axios';

export const state = {
  layoutType: 'vertical',
  layoutWidth: 'fluid',
  leftSidebarType: 'light',
  topbar: 'light',
  loader: false,
  mode: "light",
  sideMenu: {},
  subitems: {},
  folderId:null,
  stampId:null,
};

export const getters = {
  subitems: state => state.subitems,
};

export const mutations = {
  // CHANGE_LAYOUT(state, layoutType) {
  //   state.layoutType = layoutType;
  // },
  // CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
  //   state.layoutWidth = layoutWidth;
  // },
  // CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
  //   state.leftSidebarType = leftSidebarType;
  // },
  // CHANGE_TOPBAR(state, topbar) {
  //   state.topbar = topbar;
  // },
  // LOADER(state, loader) {
  //   state.loader = loader;
  // },
  // CHANGE_MODE(state, mode) {
  //   state.mode = mode;
  // },
  CHANGE_SIDE_MENU(state, sideMenu) {
    state.sideMenu = sideMenu;
   },
  SET_SUBITEMS_DATA(state, subitems) {
    state.subitems = subitems;
  },
  SET_FOLDERID(state, folderId) {
    state.folderId = folderId;
  },
  SET_STAMPID(state, stampId) {
    state.stampId = stampId;
  },
};

export const actions = {
  // changeLayoutType({ commit }, { layoutType }) {
  //   commit('CHANGE_LAYOUT', layoutType);
  // },
  // changeLayoutWidth({ commit }, { layoutWidth }) {
  //   commit('CHANGE_LAYOUT_WIDTH', layoutWidth);
  // },
  // changeLeftSidebarType({ commit }, { leftSidebarType }) {
  //   commit('CHANGE_LEFT_SIDEBAR_TYPE', leftSidebarType);
  // },
  // changeTopbar({ commit }, { topbar }) {
  //   commit('CHANGE_TOPBAR', topbar);
  // },
  // changeLoaderValue({ commit }, { loader }) {
  //   commit('LOADER', loader);
  // },
  // changeLayoutMode({ commit }, { mode }) {
  //   commit("CHANGE_MODE", mode);
  // },
  redirectFolderId({ commit }, { folderId }) {
    commit("SET_FOLDERID", folderId);
  },
  stampId({ commit }, { stampId }) {
    commit("SET_STAMPID", stampId);
  },
  async setSideMenu({ commit }) {
    try {
      const response = await axios.get(process.env.VUE_APP_URL + 'api/folder/get-all');
      commit("CHANGE_SIDE_MENU", response.data.result);
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  async fetchSubitemsData({ commit }, { requestData }) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_STAMPBOX_BACKEND_URL}api/dataportal/create-stamp`, requestData);
      commit('SET_SUBITEMS_DATA', response.data.result.stamp_item);
      return response;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  },
};
